import styled from 'astroturf/react'

export const BlueTabSectionWrapper = styled('div')`
  display: flex;
`

export const ContentWrapper = styled('div')`
  padding-bottom: 24px;
`

export const TabWrapper = styled('div')`
  min-width: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  overflow: hidden;
  margin-bottom: 5px;
`

export const BlueLineWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  height: calc(100% - 5px);
  margin-top: 5px;
`

export const BlueLine = styled('div')`
  width: 1px;
  height: 100%;
  background-color: rgba(0, 107, 255, 0.5);
`

export const Circle = styled('div')`
  width: 15px;
  height: 15px;
  border-radius: 50%;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.3);
  position: relative;
`

export const BlueCircle = styled('div')`
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: rgba(0, 107, 255, 0.5);
  top: 2.5px;
  left: 2.5px;
`
