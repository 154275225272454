import styled from 'astroturf/react'
import { ContentWrapper } from '../blue-tab-section/styles'

export const DateHeading = styled('p')`
  font-weight: 700;
  font-size: 12px;
  color: rgba(26, 26, 26, 0.6);
  font-style: 'Proxima Nova';
  letter-spacing: 1.2px;
  text-transform: uppercase;
`

export const ReleaseNoteWrapper = styled('div')`
  padding-top: 16px;

  &:first-of-type {
    padding-top: 0;
  }

  div:last-of-type {
    ${ContentWrapper} {
      padding-bottom: 0;
    }
  }
`

export const MonthTitleWrapper = styled('div')`
  margin-bottom: 16px;
`
