import React from 'react'

// Components
import {
  BlueCircle,
  BlueLine, BlueLineWrapper, BlueTabSectionWrapper, Circle, ContentWrapper, TabWrapper
} from './styles'

// TODO Refactor Hero components and hero content models
export const BlueTabSection = ({ children }) => {
  return (
    <BlueTabSectionWrapper>
      <TabWrapper>
        <Circle>
          <BlueCircle />
        </Circle>
        <BlueLineWrapper>
          <BlueLine />
        </BlueLineWrapper>
      </TabWrapper>
      <ContentWrapper>
        {children}
      </ContentWrapper>
    </BlueTabSectionWrapper>
  )
}
