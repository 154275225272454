import React from 'react'
import { Heading } from 'src/components/contentful/rich-text/styles'
import { BlueTabSection } from '../blue-tab-section/blue-tab-section'
import { RichText } from 'src/components/contentful/rich-text'
import formatDate from 'src/util/formatDate'
import { DateHeading, MonthTitleWrapper, ReleaseNoteWrapper } from './styles'

export default function Items({ currentItems }) {
  return currentItems && currentItems.map((monthlyEntry) => (
    <ReleaseNoteWrapper key={monthlyEntry.title}>
      <MonthTitleWrapper>
        <Heading
          as="h2"
          headingSize="h3"
        >
          {monthlyEntry.title}
        </Heading>
      </MonthTitleWrapper>
      {monthlyEntry.body.references.map((releaseNote) => (
        releaseNote.publishDate && (
        <BlueTabSection key={releaseNote.title}>
          <div>
            <DateHeading>
              {formatDate(releaseNote.publishDate)}
            </DateHeading>
            <Heading
              as="h3"
              headingSize="h4"
            >
              {releaseNote.title}
            </Heading>
            {releaseNote.body && (
            <RichText body={releaseNote.body} />
            )}
          </div>
        </BlueTabSection>
        )
      ))}
    </ReleaseNoteWrapper>
  ))
}
