import React from 'react'

import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import { MainLayout } from 'src/components/layout/main-layout'

import {
  Heading, Text, RichTextLink, Paragraph
} from 'src/components/contentful/rich-text/styles'

import PaginatedItems from 'src/components/widgets/pagination'

export default function ReleaseNotes({
  data: {
    contentfulPage: page
  },
  location
}) {
  const {
    body,
    padding
  } = page

  return (
    <MainLayout
      location={location}
      padding={padding}
      hasSidebar={false}
    >
      <Heading
        as="h1"
        headingSize="h1"
      >
        See what’s new with Calendly’s API
      </Heading>
      <Paragraph>
        <Text>RSS: </Text>
        <RichTextLink url="/rss.xml">https://developer.calendly.com/rss.xml</RichTextLink>
      </Paragraph>
      <Paragraph>
        <Text>Atom: </Text>
        <RichTextLink url="/atom.xml">https://developer.calendly.com/atom.xml</RichTextLink>
      </Paragraph>
      <PaginatedItems items={body.references} />
    </MainLayout>
  )
}

ReleaseNotes.propTypes = {
  data: PropTypes.shape({
    contentfulPage: PropTypes.shape({
      meta: PropTypes.shape({}).isRequired,
      body: PropTypes.shape({})
    })
  }).isRequired
}

export const query = graphql`
  query {
    contentfulPage(slug: {eq: "release-notes"}) {
      meta {
        pageTitle
        metaDescription
        identifier
        ogTitle
        ogDescription
        ogImage {
          file {
            url
          }
        }
        twitterTitle
        twitterDescription
        twitterImage {
          file {
            url
          }
        }
      }
      padding
      type
      body {
        references {
          ... on ContentfulWidgetReleaseNote {
            id
            title
            body {
              references {
                ... on ContentfulWidgetReleaseNoteDailyUpdate {
                  id
                  title
                  publishDate
                  body {
                    references {
                      ... on Node {
                        ... on ContentfulCodeBlock {
                          widgetType: __typename
                          contentful_id
                          description
                          languages
                          code {
                            code
                          }
                          includeLineNumbers
                        }
                      }
                    }
                    raw
                  }
                }
              } 
            }
          }
        }
      }
    }
  }
`
