import styled from 'astroturf/react'

export const SelectWrapper = styled('div')`
  display: flex;
  min-width: 202px;
  align-items: center;
`

export const PaginationNavigationWrapper = styled('div')`
  display: flex;
  align-items: center;
  min-width: 202px;

  @media screen and (max-width: 500px) {
    margin-top: 20px;
  }
`

export const SelectElementWrapper = styled('div')`
  width: 100%;
  margin-left: 10px;
`

export const SelectLabelWrapper = styled('div')`
  width: 55px;
`

export const ItemNumber = styled('div')`
  margin-right: 15px;
`

export const Previous = styled('div')`
  color: #0069FF;
  position: relative;
  padding-left: 10px;
`

export const Next = styled('div')`
  color: #0069FF;
  position: relative;
  padding-right: 10px;
`

export const PreviousArrow = styled('span')`
  display: block;
  width: 8px;
  height: 8px;
  border-top: 2px solid #0069FF;
  border-left: 2px solid #0069FF;
  position: absolute;
  top: 7px;
  left: 0;
  transform: rotate(-45deg);
`

export const NextArrow = styled('span')`
  display: block;
  width: 8px;
  height: 8px;
  border-top: 2px solid #0069FF;
  border-left: 2px solid #0069FF;
  position: absolute;
  top: 8px;
  right: 0;
  transform: rotate(135deg);

`

export const PaginationWrapper = styled('div')`
  margin-top: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  @media screen and (max-width: 500px) {
    margin-top: 48px;
  }

  ul {
    list-style: none;
    padding: 0;
    display: flex;
  }

  ul li:not(:first-child):not(:last-child) {
    display: none;
  }

  ul li:first-child {
    margin-right: 15px;
  }

  ul li a[class=" disable"] {
    cursor: unset;
  }

  ul li a[class=" disable"] div {
    color: rgba(26, 26, 26, 0.6);

    span {
      border-color: rgba(26, 26, 26, 0.6);
    }
  }
`
