import React, { useEffect, useState } from 'react'
import ReactPaginate from 'react-paginate'
import Items from '../release-notes-item'

import {
  Select, Control, Options, Option
} from '@calendly/ui/components/select'
import {
  SelectElementWrapper, SelectWrapper, SelectLabelWrapper, PaginationWrapper, ItemNumber, PaginationNavigationWrapper, Next, NextArrow, Previous, PreviousArrow
} from './styles'

const options = [
  { value: 1, name: '1 month' },
  { value: 3, name: '3 months' },
  { value: 6, name: '6 months' },
  { value: 12, name: '12 months' }
]

export default function PaginatedItems({ items }) {
  const [currentItems, setCurrentItems] = useState(null)
  const [pageCount, setPageCount] = useState(0)
  const [itemOffset, setItemOffset] = useState(0)
  const [currentPage, setCurrentPage] = useState(0)

  const [itemsPerPage, setItemsPerPage] = useState(3)

  const selectedItem = options.find(({ value }) => value === itemsPerPage)

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage
    setCurrentItems(items.slice(itemOffset, endOffset))
    setPageCount(Math.ceil(items.length / itemsPerPage))

    const newOffset = (currentPage * itemsPerPage) % items.length
    setItemOffset(newOffset)

    window.scroll({
      top: 0,
      behavior: 'smooth'
    })
  }, [itemOffset, items, itemsPerPage, currentPage])

  const handlePageChange = (event) => {
    setCurrentPage(event.selected)
  }

  const handleItemPerPageChange = (itemPerPage: number) => {
    setItemsPerPage(itemPerPage)
    setCurrentPage(0)
  }

  return (
    <>
      <Items currentItems={currentItems} />
      <PaginationWrapper>
        <SelectWrapper>
          <SelectLabelWrapper>
            Show
          </SelectLabelWrapper>
          <SelectElementWrapper>
            <Select value={itemsPerPage} onChange={(event) => handleItemPerPageChange(event)}>
              <Control placeholder="Select a number of items" disabled={false}>
                {selectedItem && selectedItem.name}
              </Control>
              <Options>
                {options.map(({ value, name }) => (
                  <Option key={value} value={value}>
                    {name}
                  </Option>
                ))}
              </Options>
            </Select>
          </SelectElementWrapper>
        </SelectWrapper>
        <PaginationNavigationWrapper>
          <ItemNumber>
            {`${itemOffset + 1} - ${(itemOffset + 1) + currentItems?.length - 1} of ${items.length}`}
          </ItemNumber>
          <ReactPaginate
            breakLabel="..."
            pageCount={pageCount}
            renderOnZeroPageCount={null}
            disabledLinkClassName="disable"
            forcePage={currentPage}
            onPageChange={handlePageChange}
            nextLabel={(
              <Next>
                Next
                {' '}
                <NextArrow />
              </Next>
            )}
            previousLabel={(
              <Previous>
                <PreviousArrow />
                {' '}
                Previous
              </Previous>
            )}
          />
        </PaginationNavigationWrapper>
      </PaginationWrapper>
    </>
  )
}
